<template>

  <template v-if="isBootstrapDataLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <FormSelect
      type="rich"
      ref="focusElement"
      :isSearchable="true"
      :formName="formName"
      fieldName="contractId"
    />

    <form @submit="submit" :class=" { 'show': selectedContractOption }">
      <LoadingOverlay type="light" v-if="form.isLoading || isCheckingContractIcon" />
      <FormSubmitErrors :formName="formName" />
      <FormSelect :formName="formName" fieldName="networkId" :isSearchable="true" />
      <FormInput :formName="formName" fieldName="address">
        <template #label-extra-content>
          <Tooltip class="self-center">
            <template #trigger>
              <InfoIcon />
            </template>
            <p>
              This field is automatically converted a checksum-formatted address.
            </p>
          </Tooltip>
        </template>
      </FormInput>
      <FormSelect :formName="formName" fieldName="type" v-if="formFields.type.value">
        <template #label-extra-content>
          <Tooltip class="self-center">
            <template #trigger>
              <InfoIcon />
            </template>
            <p>
              This field is automatically set by the API during the ABI type
              detecting process and can not be set manually.
            </p>
          </Tooltip>
        </template>
      </FormSelect>
      <div class="flex space-x-8">
        <FormInput :formName="formName" fieldName="name" />
        <FormInput :formName="formName" fieldName="symbol" />
      </div>
      <button type="submit" :disabled="form.isLoading || isCheckingContractIcon || !isFormValid">{{ isUpdating ? 'Update' : 'Submit' }}</button>
    </form>

  </template>

</template>

<script>

  import { markRaw } from 'vue'
  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useFilters from '@/composables/useFilters'

  import FormInput from '@/components/forms/FormInput.vue'
  import FormSelect from '@/components/forms/FormSelect.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  import AddIcon from '@/assets/icons/add.svg'
  import InfoIcon from '@/assets/icons/info.svg'
  import ContractIcon from '@/assets/icons/smart-contract.svg'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    components: {
      Tooltip,
      InfoIcon,
      FormInput,
      FormSelect,
      LoadingIcon,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'adminContractForm'

      const { paramCase } = useFilters()
      const { form, isFormValid, focusElement } = useForm({ formName })

      return {
        form,
        formName,
        paramCase,
        isFormValid,
        focusElement,
      }

    },
    computed: {
      ...mapState('app', [
        'networkOptions',
        'contractOptions',
        'isBootstrapDataLoading',
      ]),
      formFields() {
        return this.form.fields
      },
      isUpdating() {
        return this.selectedContractOption && this.selectedContractOption.value !== 'add-new-contract'
      },
      selectedContractOption() {

        if (!this.formFields.contractId.value) return null

        const selectedOption = this.formFields.contractId.options.find((option) => {
          return option.value === this.formFields.contractId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedNetworkOption() {

        if (!this.formFields.networkId.value) return null

        const selectedOption = this.formFields.networkId.options.find((option) => {
          return option.value === this.formFields.networkId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
    },
    watch: {
      selectedContractOption(newValue, oldValue) {

        if (!newValue) return

        this.$store.commit('forms/RESET_FORM', this.formName)

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: false,
        })

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'address',
          formName: this.formName,
          newValue: false,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'contractId',
          newValue: newValue.value,
        })

        if (newValue.value === 'add-new-contract') return

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: true,
        })

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'address',
          formName: this.formName,
          newValue: true,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: newValue.apiRecord.networkId,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'address',
          formName: this.formName,
          newValue: newValue.apiRecord.address,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'name',
          formName: this.formName,
          newValue: newValue.apiRecord.name,
        })

        if (newValue.apiRecord.symbol) {
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: 'symbol',
            formName: this.formName,
            newValue: newValue.apiRecord.symbol,
          })
        }

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'type',
          formName: this.formName,
          newValue: newValue.apiRecord.type,
        })

      },
    },
    data() {
      return {
        isCheckingContractIcon: false,
      }
    },
    created() {
      this.updateContractOptions()
    },
    methods: {
      updateContractOptions() {

        const newContractOptions = this.contractOptions
          .filter((contract) => {
            return contract.apiRecord.type !== 'base'
          })

        newContractOptions.unshift({
          value: 'add-new-contract',
          label: 'Add new contact',
          icon: markRaw(ContractIcon),
          descriptionIcon: markRaw(AddIcon),
          description: 'Add a new contract for any supported network',
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'contractId',
          newOptions: newContractOptions,
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'networkId',
          newOptions: this.networkOptions,
        })

      },
      // do a preliminary check to make sure the admin upload an image to S3
      checkContractIconUrl() {

        this.isCheckingContractIcon = true

        return new Promise((resolve, reject) => {

          const contractIcon = new Image()

          contractIcon.src = `https://content.esprezzo.io/dispatch/icons/crypto/${this.selectedNetworkOption.apiRecord.slug}/${this.formFields.address.value}.svg`

          contractIcon.addEventListener('load', () => {
            resolve()
          })

          contractIcon.addEventListener('error', (error) => {
            reject(new Error(`Contract icon does not appear to exist in S3 bucket (make sure the file name uses the checksum-formatted address!), expected URL is: ${contractIcon.src}`))
          })

        })
          .finally(() => {
            this.isCheckingContractIcon = false
          })

      },
      submit($event) {

        if ($event) $event.preventDefault()

        this.checkContractIconUrl()
          .then(() => {

            this.$store.commit('forms/SET_SUBMIT_METHOD', {
              newSubmitMethod: this.isUpdating ? 'put' : 'post',
              formName: this.formName,
            })

            this.$store.commit('forms/SET_SUBMIT_ENDPOINT', {
              newSubmitEndpoint: this.isUpdating ? `/contracts/${this.selectedContractOption.apiRecord.id}` : '/contracts',
              formName: this.formName,
            })

            return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
              .then(() => {

                // @NOTE: we save isUpdating here since restting the form will reset
                //  the flag to false
                const { isUpdating } = this

                this.$store.commit('forms/RESET_FORM', this.formName)

                this.$store.dispatch('toast/CREATE_TOAST', {
                  text: `Contract ${isUpdating ? 'updated' : 'created'}!`,
                  type: 'success',
                })

                if (!isUpdating) {
                  this.$store.commit('forms/SET_FIELD_VALUE', {
                    formName: this.formName,
                    fieldName: 'contractId',
                    newValue: 'add-new-contract',
                  })
                }

                return this.$store.dispatch('app/REFRESH_NETWORKS_AND_CONTRACTS')
                  .then(() => {
                    this.updateContractOptions()
                  })

              })

          })
          .catch((error) => {

            let errorMessage = this.$store.state.api.dispatchAPIErrors.unknown_error

            if (
              error.response
              && error.response.status === 400
              && error.response.data !== 'Bad Request'
              && typeof error.response.data === 'string'
            ) {
              errorMessage = error.response.data
            }

            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not ${this.isUpdating ? 'update' : 'create'} contract: ${errorMessage}`,
              type: 'error',
            })

          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

  form
    @apply h-0
    @apply mt-8
    @apply relative
    @apply opacity-0
    @apply transition-opacity
    @apply pointer-events-none

    &.show
      @apply h-auto
      @apply opacity-100
      @apply pointer-events-auto

</style>
