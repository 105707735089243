<template>
  <div class="loading-overlay" :class="[type]">
    <LoadingIcon :size="iconSize" :type="iconType" />
    <div class="message" v-if="message">{{ message }}</div>
  </div>
</template>

<script>

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      LoadingIcon,
    },
    props: {
      type: {
        type: String,
        default: 'dark',
      },
      iconType: {
        type: String,
      },
      iconSize: {
        type: String,
      },
      message: {
        type: String,
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-overlay
    @apply px-8
    @apply z-40
    @apply w-full
    @apply h-full

    @apply bg-white
    @apply bg-opacity-50

    @apply top-0
    @apply left-0
    @apply absolute

    @apply flex
    @apply flex-col
    @apply items-center
    @apply justify-center

    &.dark
      @apply bg-purple-1000
      @apply bg-opacity-50

    .message
      @apply mt-2
      @apply text-lg
      @apply font-bold
      @apply text-white

</style>
