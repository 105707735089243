<template>
  <div class="alert error" v-if="form.submitErrors.length !== 0">
    <template v-if="form.submitErrors.length === 1">
      {{ form.submitErrors[0] }}
    </template>
    <template v-else>
      Please fix the following errors:
      <ul>
        <li
          :key="index"
          v-for="(error, index) in form.submitErrors"
        >
          {{ error }}
        </li>
      </ul>
    </template>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  export default {
    props: {
      formName: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState('forms', {
        form(state) {
          return state[this.formName]
        },
      }),
    },
  }

</script>

<style lang="stylus" scoped>

  ul
    @apply mt-6

</style>
